// Must be the first import
import "./common/preact-debug";

import { render } from "preact";
import { Home } from "./imagefeed/components/Home/Home";

const elHome = document.getElementById("home-container-body");

if (elHome) {
  $(() => {
    render(
      <Home useHtmlFeed={elHome.dataset.useHtmlFeed === "true"} />,
      elHome
    );
  });
}
